// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `List`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `Description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  dashboard: `Dashboard`,
  plans: `Plans`,
  plan: `Plan`,
  customer: `Customer`,
  setting: `Setting`,
  amount2: `Amount`,

  //all message const by language
  //plan Create Message
  formFillingCurrectly: `Please fill all details correctly!`,
  planCreateSuccess: `Plan creation successful!`,
  planPhaseCreateSuccess: `Plan Pricing Model created!`,
  unableToLogout: `Unable to logout!`,
  notFound: `Not found`,
  //
  //business Index Page
  valideAddress: `Please provide a valid address`,
  businessDetailsAddedSuccess: `Business details added successfully`,
  //pageSetup
  oneCarousalImage: `Provide atleast one carousal image `,
  pageSetupSuccess: `Page setup successful`,

  //Register Form Text
  registerSuccess: `Merchant registration successful!`,

  //LoginForm Text
  loginSuccess: `Login Successful!`,

  //customer

  createSuccess: `User creation successful!`,

  //signature Error

  signatureError: `Please Provide Signatures`,

  //account added

  accountAdded: `Account added successfully!`,

  //business Added success full
  businessAdded: `Business details added successfully`,

  //detailed

  ///Yup Validation

  firstNameRequired: `First name is required`,
  valideEmailAddress: `Please provide valid email address`,
  emailRequired: `Email is required`,
  mustBeNumberFormate: `Must be a number format`,
  phoneNumberRequired: `Phone number is required`,
  selectCountryCode: `Select Country Code`,
  passwordIsRequired: `Password is required`,
  passwordMatcher: `Password must contain atleast 8 characters, one UPPERCASE, one Number and one special case character`,
  confirmPasswordRequired: `Confirm password is required`,
  confirmPasswordMatch: `Passwords don't match`,
  register: `Register`,
  businessNameRequired: `Business name is required`,
  ownerFName: `Merchant first name is required`,
  ownerLName: `Merchant last name is required`,
  businessTypeRequire: `Business type is required`,
  addressIsRequire: `Address is required`,
  cityIsRequire: `City is required`,
  ssnIsRequire: `Last 4 digits of SSN is required`,
  ssnNumber: `Invalid last 4 digits of SSN Number`,
  postelCodeIsRequire: `Postal code is required`,
  doesntlikePostelCode: `Postal code invalid`,
  selectState: `Select State`,
  stateIsRequire: `State is required`,
  countryIsRequire: `Country is required`,
  dobIsRequire: `DOB is required`,
  doesntLikePhoneNumber: `Invalid Phone Number`,
  phoneNumberCantStartWithMinus: `Phone number cannot start with a minus`,
  phoneNumberCantStartWithDecimal: `Phone number cannot include a decimal point`,
  tenDigitRequire: `Must be exactly 10 digits`,
  doesntLikebuisnessTaxId: `Business Tax ID invalid`,
  taxIdRequire: `Tax ID is required`,
  categoryIsRequire: `Category is required`,

  //business Details
  title: `Business Details Page`,
  businessDetails: `Business Details`,
  enterBusinessDetails: `Enter Business Details`,
  businessName: `Business Name`,
  selectBusinessType: `Select Business Type`,
  individual: `Individual`,
  company: `Company`,
  nonProfit: `Non Profit`,
  gov: `Government Entity`,
  businessTaxIdLabel: `Business Tax ID (EIN)`,
  selectCategory: `Select Category`,
  firstName: `First Name`,
  lastName: `Last Name`,
  lastFoutOfDigit: `Last 4 SSN Digits`,
  address: `Address`,
  zipCode: `Zip Code`,
  city: `City`,
  ownerDob: `Owner's Date Of Birth`,
  businessAddButton: `Save & Next`,
  businessTaxMatch: `Must be only digits`,
  digitMatch: `Must be exactly 9 digits`,
  phone: `Phone`,
  emailheader: `Email`,

  //login Text

  email: `Email Address`,
  password: `Password`,
  forgotPassword: `Forgot password?`,
  login: `Login`,
  subscriptionAppLoginTitle: `Hi, Welcome to Subscription App`,
  signIn: `Sign in`,
  getStarted: `Get Started`,
  dontHaveAnAccount: `Don’t have an account?`,
  enterDetailsBelowLogin: `Enter your details below.`,

  //Register Text
  alreadyHaveAnAccount: `Already have an account?`,
  titleText: `Manage your business effectively with Subscription App`,
  letsGetStarted: `Let's get started..`,
  registerBusinessWithinAMinuts: `Register your business in few minutes.`,
  byRegistringIAgree: `By registering, I agree to Subscription.App`,
  termAndCondition: `Terms of Service`,
  privacyAndPolicy: `Privacy Policy`,
  name: `Name`,
  selectCountry: `Select Country`,
  phoneNumber: `Phone Number`,
  businessPhoneNumber: `Business Phone Number`,

  //pageSetup
  pageSlugMinValidation: `Username must be atleast 3 character`,
  pageSlugMaxValidation: `Username cannot be greater then 50 character`,
  aboutMinMaxValidation: `Enter atleast 100 words`,
  logoIsRequire: `Logo is required`,
  themeColor: `Select your theme color`,
  pageSetupTitle: `User: Account Settings`,
  pageSetupHeader: 'Setup your business webpage',
  companyUserName: `Select Company's ID`,
  defaultValidation: `This would be your unique identifier for your company.`,
  aboutUsPlaceholder: `Enter description of your company, minimum 100 words required!`,
  facebook: `Facebook`,
  instagram: `Instagram`,
  whatsapp: `Whatsapp`,
  linedin: `Linkedin`,
  twitter: `Twitter`,
  youtube: `Youtube`,
  tiktok: `TikTok`,
  pinterest: `Pinterest`,
  pageSetupButton: `Go Live`,
  httpValidation: `Must Match URL format`,
  slugTitleBar: `Minimum 4 Character Required`,

  //Plan List

  planList: `Plan List`,
  newPlan: `New Plan`,
  nameRequired: `Name is required`,
  enterAtleastFiftyChar: `Enter atleast 500 character`,
  enterAtleastTenWord: `Enter atleast 10 character`,
  ternOnOrOff: `Please Turn On or Off`,
  trialDays: `Trial Days should not to be Empty`,
  contractMonthIsRequired: `Contract Month is required`,
  amountIsRequired: `Amount is required`,
  creditDaysIsRequire: `Passes Field is required`,
  creditDays: `Passes`,

  isNumber: `This must be a number`,
  limitDays: `Limit is required when you select limited`,
  plansCreateANew: `Plans: Create a new plan`,
  createPlanTitle: `Create a new Plan`,
  //labels
  planName: `Plan Name`,
  passesLimit: `Passes Limit`,
  noLimit: `No Limit`,
  limited: `Limited`,
  noOfPassesDays: `No. of Passes Days`,
  planType: `Plan Type`,
  monthly: `Monthly`,
  yearly: `Yearly`,
  weekly: `Weekly`,
  daily: `Daily`,
  number: `Number`,
  initialAmount: `Initiation Amount`,
  noSubscritionAvai: `Number of subscriptions available`,
  unlimited: `Unlimited`,
  noLimitedDays: `No. of Subscription`,
  trials: `Trials`,
  trialDaysLabel: `Trial Days`,
  agreement: `Agreement`,
  preview: `Preview`,
  createPlan: 'Create Plan',

  //plan Phase
  pricingModel: `Plan: Pricing`,
  createPricingModel: `Activate Plan`,
  pricingModelHeading: `Pricing Model`,
  model: `Pricing`,
  amountValidation: `You Should Not Enter Empty`,
  amount: `AMOUNT`,
  duration: `DURATION`,
  totalAmount: `Total Amount:`,
  action: `Action`,
  sourceType: `Source Type`,
  statementDescripto: `Description`,

  //customers
  customers: `Customers`,
  newCustomer: `New Customer`,
  dense: `Dense`,
  clearSignature: `Clear Signature`,
  signature: `Signature`,
  customerTitle: `Customer: Create a new Customer`,
  createNewCustomer: `Create a new Customer`,
  selectPlan: `Select Plan`,
  line1: `Line1`,
  postalCode: `Postal Code`,
  cardDetails: `Card Details`,
  createCustomer: `Create Customer`,
  verification: `Verification Under Process`,

  //setting
  bankDetails: `Bank Detail`,
  business: `Business`,
  pageSetup: `Page Setup`,
  myPlan: `My Plan`,
  integration: `Integration`,
  myPage: `My Page`,
  settingPageTitle: `Merchant: Account Settings`,
  accountSetting: `Account Settings`,
  detailForPayout: `Add Account Details for PayOut`,
  forPayoout: `Account Details For Payout`,
  print: `Print`,
  subscribe: `SCAN TO SUBSCRIBE`,
  address2: `Address:`,
  powerBy: `Powered by`,
  contact: `Contact:`,
  accountHolder: `Account Holder Name`,
  bankName: `Bank Name`,
  accountLast4: `Account Last 4 Digits`,
  routingNumber: `Routing Number`,
  accountNumber: `Account Number`,
  accountHolderType: `Account Holder Type`,
  checking: `Checking`,
  futsu: `Futsu`,
  savings: `Savings`,
  toza: `Toza`,
  accountType: `Select Account Type`,
  addbank: `Add Bank`,
  invoiceHistory: `Invoice History`,
  pdf: `PDF`,
  allInvoice: `All invoices`,
  noPlan: `No Plan`,
  popular: `POPULAR`,
  close: `Close`,
  type: 'Type',
  currency: `Currency`,
  fee: `Stripe Transaction Fee`,

  //setting-> BUSINESS

  //plan -> ViewGenral
  amountSmall: `Amount`,
  durationSmall: `Duration`,

  //plan -> totalCustomer
  totalCustomer: `Total Customer`,

  //plan -> ViewPlanPhase
  recurring: `Recurring`,
  iteration: `Iteration`,

  //customer -> plan
  status: `Status`,
  limit: `Limit`,
  monthelyLimit: `Monthely Limit`,
  category: `Category`,
  categoryName: `Category Name`,
  contract: `Contract`,

  //shaduled
  successShaduled: `Call scheduled  successfully`,
  shaduledTitleText: `Feeling overwhelmed? Schedule a free setup meeting to get started`,
  shaduledCallText: `We provide a personal assistant for your profile setup for free, if you want.`,
  dateTimePicker: `DateTimePicker`,
  shaduledCallExpert: `Schedule date and time to get a call from our experts.`,
  call: `Schedule call`,
  skip: `Skip`,
  commentIsRequire: `Comment is required`,
  //plan view
  createPlanPhaseTitle: `Pricing Model not updated`,

  //myPlanCard
  myPlanTitle: `Your Plan`,
  cardCreateSuccess: `Card added Successfully !`,
  active: `Active`,
  paymentMethod: `Payment Method`,
  addNewCard: ` Add New Card`,
  //my page

  // Top Alert
  clickHere: ` Click Here`,
  firstPlane: `Create Your First Plan`,
  firstAddBank: `First Add Bank & Startup Your Business `,
  enjoyYourself: `Do Subscribe, Enjoy yourself!!`,
  subscribtext: `Subscribe`,
  readMore: `Read More`,
  acceptAllTermsCondition: `Accept terms & condition`,
  modeltext: `Verification is under process. we will notify you once you will get verified.`,

  //myPlan
  createSuccesss: `Plan Subscribed Succesfully`,

  clickToAddBank: `Click Here`,
  purchasePlan: `Purchase Plan`,

  //payout -> Index
  pending: `Pending`,
  instantAvailable: `Instant Available`,
  available: `Available`,

  //payout

  payout: `Payout`,
  payoutList: `Payout List`,
  help: `Help ?`,
  copy: `Copy`,

  maxValidation: `Initial Amount must be less than or equal to 5000`,
  maxValidationAmount: `Amount must be less than or equal to 5000`,
  noUser: `No User Found`,
};

export default en;
