import { baseApi } from '../base';
import { roleResponse } from './type';

const roleApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRole : build.query<roleResponse[], any>({
      query: () => '/role',
      providesTags: ['role'],
    }),
  }),
});

export const {useGetRoleQuery} = roleApi




