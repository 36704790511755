import { baseApi } from '../base';
import { merchantBusinessRequest, merchantRequest } from './type';

type GetMerchantRequest = {
  limit: number;
  offset: number;
};

const merchantApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMerchant: build.query<any, any>({
      query: ({ limit, offset }) => `/merchant?limit=${limit}&offset=${offset}`,
      // query: ({ limit }) => '/merchant',
      providesTags: ['merchant'],
    }),
    createMerchant: build.mutation<any, merchantRequest>({
      query: (data) => ({
        url: `https://subscribe-staging.wl.r.appspot.com/merchant/api/v1/signup`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['user'],
    }),
    createBusiness: build.mutation<any, merchantBusinessRequest>({
      query: ({ mid, ...data }) => ({
        url: `merchant/${mid}/business`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['merchant'],
    }),
    approveMerchant: build.mutation({
      query: (mid) => ({
        method: 'POST',
        url: `/merchant/${mid}/approve`,
      }),
      invalidatesTags: ['merchant'],
    }),
    getMerchantyById: build.query({
      query: (mid) => ({
        method: 'GET',
        url: `/merchant/${mid}`,
      }),
    }),
    deleteMerchant: build.mutation({
      query: (mid) => ({
        method: 'POST',
        url: `/merchant/view/${mid}`,
      }),
    }),
    suspendMerchant: build.mutation({
      query: (mid) => ({
        method: 'POST',
        url: `/merchant/${mid}/suspend`,
      }),
      invalidatesTags: ['merchant'],
    }),
    getBusinessByMerchantId: build.query({
      query: (mid) => ({
        method: 'GET',
        url: `/merchant/${mid}/business`,
      }),
    }),
  }),
});

export const {
  useGetMerchantQuery,
  useCreateMerchantMutation,
  useCreateBusinessMutation,
  useApproveMerchantMutation,
  useDeleteMerchantMutation,
  useSuspendMerchantMutation,
  useGetMerchantyByIdQuery,
  useGetBusinessByMerchantIdQuery,
} = merchantApi;
