// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import cssStyles from 'src/utils/cssStyles';
import Image from 'src/components/Image';
import { useLocation, useParams } from 'react-router';
import { useGetCustomerByMerchantIdAndCustomerIdQuery } from 'src/services/customers';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  '&:before': {
    ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.darker }),
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}));

const InfoStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

export default function CustomerCovers() {
  const location = useLocation();

  const mid = location.pathname.split('/').at(-2);

  const id = location.pathname.split('/').at(-1);

  const { data } = useGetCustomerByMerchantIdAndCustomerIdQuery({ mid, id });

  return (
    <RootStyle>
      <InfoStyle>
        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            mb: { md: 8 },
            color: 'common.white',
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography variant="h4">{data?.name}</Typography>
          <Typography sx={{ opacity: 0.8 }}>{data?.phone}</Typography>
        </Box>
      </InfoStyle>
      <Image
        alt="profile cover"
        src="" //public/logo/logo_full.jpg''
        sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
      />
    </RootStyle>
  );
}
