import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_ENV } from 'src/config';
import { RootState } from 'src/redux/store';

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: APP_ENV !== 'PROD' ? 'https://dev-api.subscription.app/admin/api/v1' : 'https://api.subscription.app/admin/api/v1',
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).user;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: ['category', 'merchant', 'tax', 'customer', 'plan', 'user', 'business', 'pages', 'blog','connect', "schedule" , "role" , "coupon", "dashboard"],
});
