import { useForm } from 'react-hook-form';
// import { useGetPlanByIdQuery } from 'src/services';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import {
  Divider,
  CardHeader,
  Typography,
  CardContent, Card,
  Grid,
  Stack, useTheme, TextareaAutosize, CircularProgress
} from '@mui/material';
import Label from 'src/components/Label';
import useLocales from 'src/hooks/useLocales';
import { useGetMerchantPlanByPlanIDQuery, useGetMerchantyByIdQuery, useGetPlanDetailByIdQuery, useGetPlanQuery } from 'src/services';

type FormValuesProps = {
  id: string;
  name: string;
  description: string;
  planType: string;
  initialAmount: number | string;
  amount: number;
  limit: number | null;
  trialDays: number;
  creditDays: number;
  status: string;
  contract: string;
  agreement: string;
  taxCode: string | number;
};

function ViewGeneral() {

  const { translate } = useLocales()
  const { id } = useParams();
  console.log(id);

  const { data, isFetching } = useGetPlanDetailByIdQuery({ id })
  console.log(data);

  const methods = useForm<FormValuesProps>({});

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  return (
    <>
      {isFetching ? <>

        <CircularProgress size={50} sx={{ ml: '50%', mt: '100px', mb: '50px' }} />

      </> : <>
        <Grid>
          <Grid xs={12} md={12} item>
            <Card>
              <CardHeader
                title="Plan" />
              <CardContent>
                <Stack spacing={2}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      Status
                    </Typography>
                    <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color={
                        (data?.status === 'active' && 'success') ||
                        (data?.status === 'unpaid' && 'warning') ||
                        (data?.status === 'inactive' && 'error') ||
                        'default'
                      }
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {data?.status}
                    </Label>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {translate('name')}
                    </Typography>
                    <Typography variant="subtitle1">{data?.name}</Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {translate('planType')}
                    </Typography>
                    <Typography variant="subtitle1">{data?.planType}</Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {translate('noOfPassesDays')}
                    </Typography>
                    <Typography variant="subtitle1">{data?.creditDays}</Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {translate('initialAmount')}
                    </Typography>
                    <Typography variant="subtitle1">{data?.initialAmount}</Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {translate('amountSmall')}
                    </Typography>
                    <Typography variant="subtitle1">{data?.amount}</Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {translate('durationSmall')}
                    </Typography>
                    <Typography variant="subtitle1">{data?.duration}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {translate('categoryName')}
                    </Typography>
                    <Typography variant="subtitle1">{data?.planCategory?.name}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" spacing={3}>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {translate('description')}
                    </Typography>
                    <Typography variant="subtitle1">{data?.description}</Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}></Typography>

                  </Stack>

                  <Divider />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>}


    </>
  );
}

export default ViewGeneral;