import { baseApi } from '../base';
import { dashboardResponce } from './type';
const dashboard = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDashboard: build.query<dashboardResponce, any>({
      query: () => '/dashboard',
      providesTags: ['dashboard'],
    }),
  }),
});

export const { useGetDashboardQuery } = dashboard;
