import { baseApi } from '../base';

const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAuthLogin: build.mutation<any, any>({
      query: (data) => {
        return {
          method: 'GET',
          url: `/user/${data}`,
        };
      },
    }),
  }),
});

export const { useGetAuthLoginMutation } = authApi;
