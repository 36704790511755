import { baseApi } from '../base';
import { adminUserRequest, adminUserResponce } from './type';

const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAdminUser: build.query<adminUserResponce, any>({
      query: ({ limit, offset }) => `/user?limit=${limit}&offset=${offset}`,
      providesTags: ['user'],
    }),
    createUser: build.mutation<any, adminUserRequest>({
      query: (data) => ({
        url: `/user`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['user'],
    }),
    userStatusToggel: build.mutation({
      query: (id) => ({
        method: 'PATCH',
        url: `/user/${id}`,
      }),
      invalidatesTags: ['user'],
    }),
  }),
});
export const { useGetAdminUserQuery , useCreateUserMutation, useUserStatusToggelMutation } = userApi;
