import { capitalCase } from 'change-case';
// @mui
import { styled } from '@mui/material/styles';
import { Tab, Box, Card, Tabs, Container, CardContent, Divider, Stack, TextareaAutosize, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useTabs from '../../../hooks/useTabs';
import useSettings from '../../../hooks/useSettings';
// _mock_

// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import ViewCover from './AdminPlanView/ViewCover';
import ViewGeneral from './AdminPlanView/ViewGeneral';
// sections

// ----------------------------------------------------------------------

const TabsWrapperStyle = styled('div')(({ theme }) => ({
    zIndex: 9,
    bottom: 0,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(3),
    },
}));

// ----------------------------------------------------------------------


export default function ViewPlan() {

    const { themeStretch } = useSettings();
    const { currentTab, onChangeTab } = useTabs('Plan');

    const PROFILE_TABS = [
        {
            value: 'Plan',
            icon: <Iconify icon={'grommet-icons:plan'} width={20} height={20} />,
            component: <ViewGeneral />
        },
    ];

    return (
        <Page title="User: Profile">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={'plan'}
                    links={[
                        { name: 'dashboard', href: PATH_DASHBOARD.root },
                        { name: 'plans', href: PATH_DASHBOARD.plans.root },
                        { name: 'Details' },
                    ]}
                />
                <Card
                    sx={{
                        mb: 3,
                        height: 280,
                        position: 'relative',
                    }}
                >
                    <ViewCover />

                    <TabsWrapperStyle>
                        <Tabs
                            allowScrollButtonsMobile
                            variant="scrollable"
                            scrollButtons="auto"
                            value={currentTab}
                            onChange={onChangeTab}
                        >
                            {PROFILE_TABS.map((tab) => (
                                <Tab
                                    disableRipple
                                    key={tab.value}
                                    value={tab.value}
                                    icon={tab.icon}
                                    label={capitalCase(tab.value)}
                                />
                            ))}
                        </Tabs>
                    </TabsWrapperStyle>
                </Card>

                {PROFILE_TABS.map((tab) => {
                    const isMatched = tab.value === currentTab;
                    return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                })}

            </Container>

        </Page>
    );
}