import { baseApi } from "../base";
import { scheduleResponse, updateStatusRequest } from './type';

const scheduleApi = baseApi.injectEndpoints({
    endpoints : (build) => ({
        getSchedule: build.query<scheduleResponse, any>({
            query: ({ limit, offset }) => `/schedule?limit=${limit}&offset=${offset}`,
            providesTags: ['schedule'],      
          }),
        updateStatus: build.mutation<any, updateStatusRequest>(
            {           
            query: (data)=> {
                return({
                    url: `/schedule/${data.id}/${data.action}`,
                    method: 'PATCH',
                    body: { comment: data.comment },
                })
            },
            invalidatesTags: ['schedule'],
        }),
    }),
});

export const { useGetScheduleQuery, useUpdateStatusMutation } = scheduleApi;

