// @mui
import { Grid, Stack } from '@mui/material';
import CustomerDetails from './CustomerDetails';
import CustomerPlan from './CustomerPlan';
import PlanDetailedViews from './PlanDetailedViews';

import ViewCustomer from './ViewCustomer';

// @types

// ----------------------------------------------------------------------

export default function ViewMainCustomers() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <CustomerPlan />
          {/* <PlanDetailedViews /> */}
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack spacing={3}>
          <CustomerDetails />
        </Stack>
      </Grid>
    </Grid>
  );
}
