// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
// import Label from '../../../components/Label';
// import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle id="name" src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  coupon: getIcon('ic_coupon'),
};

const navConfig = [
  // DASHBOARD
  {
    subheader: 'App',
    items: [
      // Dashboard
      { title: 'Dashboard', path: '/dashboard/app', icon: ICONS.dashboard },

      //MERCHANT
      {
        title: 'Merchant',
        path: PATH_DASHBOARD.merchant.root,
        icon: ICONS.user,
      },

      //Schedule
      {
        title: 'Schedule',
        path: PATH_DASHBOARD.schedule.root,
        icon: ICONS.calendar,
      },

      //Coupon
      {
        title: 'Coupon',
        path: PATH_DASHBOARD.coupon.root,
        icon: ICONS.coupon,
      },

      // Category
      {
        title: 'Category',
        path: PATH_DASHBOARD.category.root,
        icon: ICONS.ecommerce,
      },
      // Customer
      {
        title: 'Customers',
        path: PATH_DASHBOARD.customer.root,
        icon: ICONS.user,
      },
      // Plan
      {
        title: 'Plans',
        path: PATH_DASHBOARD.plans.list,
        icon: ICONS.coupon,
      },
      //Blog
      {
        title: 'Blog',
        path: PATH_DASHBOARD.blog.posts,
        icon: ICONS.blog,
      },
      // Users
      {
        title: 'Users',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user,
      },
    ],
  },
];

export default navConfig;
