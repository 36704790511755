import { CircularProgress } from '@mui/material';
import {
  Card,
  Grid,
  Stack,
  Divider,
  CardHeader,
  Typography,
  CardContent,
  useTheme,
} from '@mui/material';
import { useLocation } from 'react-router';
import Label from 'src/components/Label';
import useLocales from 'src/hooks/useLocales';
import { useGetCustomerByMerchantIdAndCustomerIdQuery } from 'src/services/customers';

type FormValuesProps = {
  uid: number;
  firebaseUID: string;
  stripeID: string;
  name: string;
  phone: string;
  email: string;
  line1: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  createdBy?: null;
  updatedBy?: null;
  status: string;
  comment?: null;
  createdAt: string;
  updatedAt: string;
};

function CustomerDetails() {
  const { translate } = useLocales();
  const location = useLocation();

  const mid = location.pathname.split('/').at(-2);

  const id = location.pathname.split('/').at(-1);

  const { data, isFetching } = useGetCustomerByMerchantIdAndCustomerIdQuery({ mid, id });

  const theme = useTheme();

  return (
    <>
      {isFetching ? (
        <>
          <Stack direction="row" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={50} sx={{ mt: 10, mb: 10 }} />
          </Stack>
        </>
      ) : (
        <Grid>
          <Grid item>
            <Card>
              <CardHeader title={translate('customer')} />

              <CardContent>
                <Stack spacing={2}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {translate('name')}
                    </Typography>
                    <Typography variant="subtitle1">{data?.name}</Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {translate('phoneNumber')}
                    </Typography>
                    <Typography variant="subtitle1">{data?.phone}</Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {translate('email')}
                    </Typography>
                    <Typography variant="subtitle1">{data?.email}</Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {translate('address')}
                    </Typography>
                    <Typography variant="subtitle1">
                      {data?.line1} {data?.city} {data?.state} {data?.country} {data?.postal_code}
                    </Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {translate('status')}
                    </Typography>
                    <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color={
                        (data?.status === 'active' && 'success') ||
                        (data?.status === 'unpaid' && 'warning') ||
                        (data?.status === 'inactive' && 'error') ||
                        'default'
                      }
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {data?.status}
                    </Label>
                  </Stack>

                  <Divider />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default CustomerDetails;
