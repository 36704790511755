import { baseApi } from '../base';
import { merchantPlanResponse, singlePlanSingleResponce } from './type';


const planApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPlanByMerchantId: build.query<merchantPlanResponse, any>({
      query: ({id, limit, offset}) => `/merchant/${id}/plan?limit=${limit}&offset=${offset}`,
      providesTags: ['plan'],
    }),
    merchantPlanStatusToggel: build.mutation({
      query: ({mid, id}) => ({
        method: 'PATCH',
        url: `/merchant/${mid}/plan/${id}`,
      }),
      invalidatesTags: ['plan'],
    }),
    singlePlanOfSingleMerchant: build.query<singlePlanSingleResponce, any >({
      query: ({mid, id}) => ({
      method: "GET",
      url : `/merchant/${mid}/plan/${id}`
      }),
      providesTags: ['plan'],
    })
  }),
});
export const { useGetPlanByMerchantIdQuery, useSinglePlanOfSingleMerchantQuery, useMerchantPlanStatusToggelMutation } = planApi;
