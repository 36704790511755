import { baseApi } from '../base';
import { CreatePlanRequest, getMerchantPlanByIDResponse, getsPlanResponce } from './type';

const planApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPlan: build.query<getsPlanResponce, any>({
      query: ({ limit, offset }) => `/plan?limit=${limit}&offset=${offset}` ,
      providesTags: ['plan'],
    }),
    createPlan: build.mutation<any, CreatePlanRequest>({
      query: (data) => ({
        url: `/plan`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['plan'],
    }),
    getPlanDetailById: build.query<any, any>({
      query: ({id}) => ({
        method: 'GET',
        url: `plan/${id}`
})
    }),
    planStatusToggel: build.mutation({
      query: ({mid ,id}) => ({
        method: 'PATCH',
        url: `/merchant/${mid}/plan/${id}`,
      }),
      invalidatesTags: ['plan'],
    }),
    adminPlanStatusToggel: build.mutation({
      query: (id) => ({
        method: 'PATCH',
        url: `/plan/${id}`,
      }),
      invalidatesTags: ['plan'],
    }),
    getMerchantPlanByPlanID : build.query<getMerchantPlanByIDResponse, any>({
      query: ({mid, id})=> ({
        method : "GET",
        url: `/merchant/${mid}/plan/${id}`
      })
        // query: ({mid, id}) => `/merchant/${mid}/plan/${id}`,
        // providesTags: ['plan'],
    })
  }),
});

export const { useGetPlanQuery, useCreatePlanMutation, useGetMerchantPlanByPlanIDQuery,useGetPlanDetailByIdQuery, usePlanStatusToggelMutation, useAdminPlanStatusToggelMutation } = planApi;
