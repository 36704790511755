import { baseApi } from '../base';
import { CustomerByMerchantIdAndPlanIdResponce, customerRequest, customersResponce, MerchantIdAndCustomerIdResponce } from './type';

const customersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCustomers: build.query<customerRequest, any>({
      query: ({ limit, offset }) => `/customer?limit=${limit}&offset=${offset}`,
      providesTags: ['customer'],
    }),
    getCustomerByMerchantId: build.query<customersResponce, any>({
      query: ( {id, limit, offset}) => `/merchant/${id}/customer?limit=${limit}&offset=${offset}`,
      providesTags: ['customer'],
    }),
    getCustomerByMerchantIdAndPlanId : build.query<CustomerByMerchantIdAndPlanIdResponce[], any>({
      query: ({mid, id}) => `/merchant/${mid}/plan/${id}/customer`,
      providesTags: ['customer'],
    }),
    getCustomerByMerchantIdAndCustomerId: build.query<MerchantIdAndCustomerIdResponce, any>({
      query:({mid, id})=>`/merchant/${mid}/customer/${id}`,
      providesTags: ['customer'],
    })
  }),
});
export const { useGetCustomersQuery, useGetCustomerByMerchantIdQuery, useGetCustomerByMerchantIdAndPlanIdQuery, useGetCustomerByMerchantIdAndCustomerIdQuery } = customersApi;
