import { baseApi } from '../base';


const StripConnect = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getstripConnect: build.query<any, any>({
      query: () => '/connect',
      providesTags: ['connect'],
    }),
    getPageSlug: build.query<any, any>({
        query: (pageSlug) => {
          return {
            method: 'GET',
            url: `/page/${pageSlug}`,
          };
        },
      }),
  }),
});

export const { useGetstripConnectQuery, useGetPageSlugQuery } = StripConnect;