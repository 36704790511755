import { baseApi } from '../base';
import { categoryRequest, categoryResponce } from './type';

type getCategoryResponse = {
  limit?: number;
  offset?: number;
};

const categoryApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCategory: build.query<categoryResponce, getCategoryResponse>({
      query: ({ limit, offset }) => `/category?limit=${limit || 10}&offset=${offset || 0}`,
      providesTags: ['category'],
    }),
    createCategory: build.mutation<any, categoryRequest>({
      query: (data) => ({
        url: `/category`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['category'],
    }),
    statusToggel: build.mutation({
      query: (id) => ({
        method: 'PUT',
        url: `/category/${id}`,
      }),
      invalidatesTags: ['category'],
    }),
    deleteCategory: build.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/category/${id}`,
      }),
      invalidatesTags: ['category'],
    }),
  }),
});

export const {
  useGetCategoryQuery,
  useCreateCategoryMutation,
  useStatusToggelMutation,
  useDeleteCategoryMutation,
} = categoryApi;
