import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
// import SinglePlanAccount from '../pages/dashboard/SinglePlanAccount';
import NewBlogPostForm from 'src/pages/dashboard/blog/NewBlogPostForm';
import ViewPlan from 'src/pages/dashboard/AdminPlan/ViewPlan';
import ViewCustomers from 'src/pages/dashboard/merchantCustomer/ViewCustomer';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },

            { path: 'cards', element: <UserCards /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },

        //CATEGORY
        {
          path: 'category',
          children: [
            { element: <Navigate to="/dashboard/category/list" replace />, index: true },
            { path: 'list', element: <CategoryList /> },
          ],
        },

        //SCHEDULE
        {
          path: 'schedule',
          children: [
            { element: <Navigate to="/dashboard/schedule/list" replace />, index: true },
            { path: 'list', element: <ScheduleList /> },
          ],
        },
        //Coupon
        {
          path: 'coupon',
          children: [
            { element: <Navigate to="/dashboard/coupon/list" replace />, index: true },
            { path: 'list', element: <CouponList /> },
          ],
        },
        {
          path: 'merchant',
          children: [
            { element: <Navigate to="/dashboard/merchant/list" replace />, index: true },
            { path: 'list', element: <MerchantList /> },
            { path: 'view/:mid', element: <MerchantView /> },
            { path: 'customer/:id/:id', element: <ViewCustomers /> },
            { path: 'customer/view/:id/:id', element: <PlanDetailedViews /> },
          ],
        },
        {
          path: 'Customer',
          children: [
            { element: <Navigate to="/dashboard/customer/list" replace />, index: true },
            { path: 'list', element: <CustomerList /> },
            { path: 'new', element: <CreateNewCustomer /> },
          ],
        },


        // Plans
        {
          path: 'plans',
          children: [
            { element: <Navigate to="Plans/list" replace />, index: true },
            { path: 'list', element: <PlanHome /> },
            { path: 'new', element: <PlanCreate /> },
            { path: ':id', element: <ViewPlan /> },

          ],
        },
        {
          path: 'merchantPlan',
          children: [
            { element: <Navigate to="Plans/list" replace />, index: true },
            { path: 'list', element: <PlanHome /> },
            { path: 'new', element: <CreateMerchatedit /> },
            { path: ':id', element: <ViewPlan /> },
            { path: 'phase/:id/:id', element: <MerchantPlanPhases /> }

          ],
        },
        // Blog
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/src/pages/dashboard/Blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogHead /> },
            { path: 'new', element: <NewBlogPostForm /> },
            { path: 'post/:id', element: <BlogPostsDetails /> }
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/src/pages/dashboard/User/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
          ],
        },

      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [

        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      children: [
        {
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          index: true,
        },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Blog

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/index')));


// ECOMMERCE



//CATEGORY
const CategoryList = Loadable(lazy(() => import('../pages/dashboard/category/Index')));

//Customer
const CustomerList = Loadable(lazy(() => import('../pages/dashboard/AllCustomer/index')));
const MerchantPlanPhases = Loadable(lazy(() => import('../pages/dashboard/MerchantPlans/PlanDetails')))

//MERCHANT
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const MerchantView = Loadable(lazy(() => import('../pages/dashboard/MerchantView')));

//CATEGORY
const MerchantList = Loadable(lazy(() => import('../pages/dashboard/merchant/Index')));
const CreateMerchatedit = Loadable(lazy(() => import('../pages/dashboard/MerchantPlans/CreateMerchantPlan')));


//Schedule
const ScheduleList = Loadable(lazy(() => import('../pages/dashboard/schedule/index')));

//Coupon
const CouponList = Loadable(lazy(() => import('../pages/dashboard/coupon/index')));



const PlanHome = Loadable(lazy(() => import('../pages/dashboard/AdminPlan/index')));
const PlanCreate = Loadable(lazy(() => import('../pages/dashboard/AdminPlan/PlanCreate')))
const MerchantPlan = Loadable(lazy(() => import('../pages/dashboard/AdminPlan/PlanCreate')))
const CreateNewCustomer = Loadable(lazy(() => import('../pages/dashboard/merchantCustomer/createCustomer')))
const PlanDetailedViews = Loadable(lazy(() => import('../pages/dashboard/merchantCustomer/PlanDetailedViews')))

// BLOG
const BlogHead = Loadable(lazy(() => import('../pages/dashboard/blog/BlogHead')));
const BlogPostsDetails = Loadable(lazy(() => import('../pages/dashboard/blog/BlogPostsDetails')))

// USER
const UserList = Loadable(lazy(() => import('../pages/dashboard/users/UserList')))
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));


// APP


// TEST RENDER PAGE BY ROLE


// MAIN


const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
