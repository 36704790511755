import { baseApi } from '../base';
import {  createBlogResponce,  getBlogResponce, blogByIdResponce, } from './type';

const blogApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBlog: build.query<getBlogResponce[], any>({
      query: () => `/blog`,
      providesTags: ['blog'],
    }),
    createBlog: build.mutation<createBlogResponce, any>({
      query: (data) => ({
        url: `/blog`,
        method: 'POST',
        body: data.formData,
      }),
      invalidatesTags: ['blog'],
    }),
    getBlogById : build.query<blogByIdResponce, any>({
        query: (id) => `/blog/${id}`,
      providesTags: ['blog'],
         
    })
  }),
});

export const { useCreateBlogMutation, useGetBlogQuery, useGetBlogByIdQuery } = blogApi;
