import { baseApi } from '../base';
import { CouponRequest, couponResponse } from './type';


const couponApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCoupon: build.query<couponResponse, any>({
      query: ({ limit, offset }) => `/coupon?limit=${limit}&offset=${offset}`,
      providesTags: ['coupon'],
    }),
    createCoupon: build.mutation<any, CouponRequest>({
      query: (data) => ({
        url: `/coupon`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['coupon'],
    }),
    deleteCoupon: build.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/coupon/${id}`,
      }),
      invalidatesTags: ['coupon'],
    }),
  })
})

export const {  useCreateCouponMutation, useDeleteCouponMutation ,useGetCouponQuery } = couponApi
